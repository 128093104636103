<template>
  <nav>
   <v-app-bar app flat color="grey lighten-5">
      <template v-if="!drawer">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title class="pl-0 pt-1">
          <v-icon class="osg-logo" size="xl">$vuetify.icons.osg-logo</v-icon>
        </v-toolbar-title>
        <template>
          <v-divider class="mx-8 grey lighten-2" vertical inset></v-divider>
          <span class="primary--text text-subtitle-1">{{ $t('login.appTitle') }}</span>
        </template>
      </template>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-navigation-drawer app width="240" height="100%" v-model="drawer">

      <template v-slot:prepend>
        <v-toolbar class="px-2" flat>
          <v-toolbar-title class="pl-0 pt-1">
            <v-icon class="osg-logo" size="xl">$vuetify.icons.osg-logo</v-icon>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        </v-toolbar>

        <v-list-item>
          <v-card color="#F4F5F7" class="rounded-card" flat>
                <v-card-title class="text-subtitle-1">
                  <v-row class="align-center">
                    <v-col>
                      <span style="font-size: 0.75rem !important;" class="secondary--text text-wrap break-word">{{tokenExtensions.userName}}</span>
                      <br>

                    </v-col>
                    <v-col cols="auto" class="px-1">
                      <v-btn
                        x-small
                        depressed
                        fab
                        color="white"
                        width="26"
                        height="26"
                        class="ml-4"
                      >
                        <v-icon size="20" color="primary">chevron_right</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
        </v-list-item>
      </template>

      <template>
        <v-list-item-group class="pt-9"
          mandatory
          color="secondary"
        >
          <v-list-item
            dense
            v-for="(navigationItem, i) in navigationItems"
            :to="navigationItem.route"
            :disabled="navigationItem.disabled"
            :key="i"
          >
            <v-list-item-content>
              <v-list-item-title
                class="text-drawer-title  secondary--text pl-2"
                v-text="$t(navigationItem.textKey)"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </template>

      <template v-slot:append>
        <v-list dense class="py-0">
          <v-divider class="pt-2"/>
          <v-list-item-content class="py-0">
            <v-list-item @click="logedOut">
              <span class="text-overline text-center osg-full-width">
                <v-icon size="16" class="mb-1" color="secondary">mdi-exit-to-app</v-icon>
                {{$t('drawer.logout')}}
              </span>
            </v-list-item>
          </v-list-item-content>
        </v-list>
      </template>

    </v-navigation-drawer>
  </nav>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const authModule = createNamespacedHelpers('auth');

export default {
  name: 'OsiguDrawer',
  data() {
    return {
      drawer: this.$vuetify.breakpoint.lg
    }
  },
  methods: {
    ...authModule.mapActions(['logout']),
    logedOut() {
      this.logout();
      this.$router.push('/login');
    }
  },
  computed: {
    ...authModule.mapGetters(['tokenExtensions']),
    navigationItems() {
      return [
        {
          textKey: 'drawer.menu.home',
          icon: '',
          route: '/home'
        }
      ];
    }
  }
};
</script>
